import { useState, useEffect } from 'react';
import { maxWidth } from '../styles/media';

export const mqMobile = [`(max-width: ${maxWidth.mobile}px)`];
export const mqTabletPortrait = [`(max-width: ${maxWidth.tabletPortrait}px)`];
export const mqTabletLandscape = [`(max-width: ${maxWidth.tabletLandscape}px)`];

export default function useMedia(queries, values, defaultValue) {
    // For SSR, query list are build on componentDidMount
    // In this way SSR will always serve defaultValue
    let mediaQueryLists = [];

    // Function that gets value based on matching media query
    const getValue = () => {
        // Get index of first media query that matches
        const index = mediaQueryLists.findIndex(mql => mql.matches);
        // Return related value or defaultValue if none
        return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
    };

    // State and setter for matched value
    const [value, setValue] = useState(getValue);

    useEffect(
        () => {
            mediaQueryLists = queries.map(q => window.matchMedia(q));
            // Event listener callback
            // Note: By defining getValue outside of useEffect we ensure that it has ...
            // ... current values of hook args (as this hook callback is created once on mount).
            const handler = () => setValue(getValue);
            // Set a listener for each media query with above handler as callback.
            mediaQueryLists.forEach(mql => mql.addListener(handler));
            // Force state update on componentMount
            handler();
            // Remove listeners on cleanup
            return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
        },
        [] // Empty array ensures effect is only run on mount and unmount
    );

    return value;
}
