import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Link from './Link';
import { linkReset } from '../styles/others';

const StyledSwitch = styled.div`
    position: relative;
    width: ${props => props.theme.spacing.s80};
    height: 100%;
    box-shadow: inset 1px 0 0 0 ${props => props.theme.colors.grey};
    text-transform: uppercase;
    z-index: 3;
`;

const StyledToggle = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const StyledDropdown = styled.div`
    position: absolute;
    top: 100%;
    width: 100%;
    display: ${props => (props.isOpen ? 'block' : 'none')};
    background: ${props => props.theme.colors.white};
    box-shadow: inset 1px 0 0 0 ${props => props.theme.colors.grey},
        inset -1px 0 0 0 ${props => props.theme.colors.grey},
        inset 0 -1px 0 0 ${props => props.theme.colors.grey};
`;

const StyledLink = styled(Link)`
    ${linkReset};
    display: block;
    text-align: center;
    padding: ${props => props.theme.spacing.s10} 0;

    &:hover {
        color: ${props => props.theme.colors.red};
    }
`;

const LangSwitch = ({ langSwitch, lang, onToggle }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdown = langSwitch
        .filter(item => item.lang !== lang)
        .map(item => (
            <StyledLink to={`/${item.lang}/`} key={item.lang}>
                {item.langShort}
            </StyledLink>
        ));

    return (
        <StyledSwitch onMouseLeave={() => setIsOpen(false)}>
            <StyledToggle onMouseEnter={() => setIsOpen(true)}>
                <span>{lang.split('-')[0]}</span>
            </StyledToggle>
            <StyledDropdown isOpen={isOpen}>{dropdown}</StyledDropdown>
        </StyledSwitch>
    );
};

export default LangSwitch;
