import { useState, useEffect } from 'react';

function getSize() {
    return {
        innerHeight: typeof window !== 'undefined' ? window.innerHeight : 0,
        innerWidth: typeof window !== 'undefined' ? window.innerWidth : 0,
        outerHeight: typeof window !== 'undefined' ? window.outerHeight : 0,
        outerWidth: typeof window !== 'undefined' ? window.outerWidth : 0,
        bodyWidth: typeof window !== 'undefined' ? document.body.clientWidth : 0
    };
}

function useWindowSize() {
    const [windowSize, setWindowSize] = useState(getSize());

    function handleResize() {
        setWindowSize(getSize());
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handleResize);
        }

        handleResize();

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('resize', handleResize);
            }
        };
    }, []);

    return windowSize;
}

export default useWindowSize;
