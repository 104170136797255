import React from 'react';
import styled from 'styled-components';
import LinkHover from './LinkHover';
import LinkAnchor from './LinkAnchor';
import Row from './Row';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';

const StyledRow = styled(Row)`
    padding: ${props => props.theme.spacing.s40} ${sizeAsBase(2)};
    min-height: 480px;

    ${media.large`
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(2)};
    `}

    & > a {
        font-weight: bold;
    }
`;

const StyledLinks = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: ${props => props.theme.spacing.s40};
`;

const StyledLinksTitle = styled.div`
    width: ${sizeAsBase(17)};
    margin-right: ${sizeAsBase(3)};
    font-weight: bold;

    ${media.large`
        width: ${sizeAsMax(17)};
        margin-right: ${sizeAsMax(3)};
    `}
`;

const StyledLinksItems = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${sizeAsBase(2)};
    grid-row-gap: ${props => props.theme.spacing.s20};
    width: ${sizeAsBase(41)};

    &:before {
        content: '';
        position: absolute;
        left: ${sizeAsBase(-3)};
        top: 0;
        bottom: 0;
        width: 1px;
        background: ${props => props.theme.colors.red};
    }

    ${media.large`
        grid-column-gap: ${sizeAsMax(2)};
        width: ${sizeAsMax(41)};
    `}
`;

const SubMenuRythmo = ({
    sub_rythmo_link,
    sub_rythmo_diagnostics,
    sub_rythmo_pathologies,
    telecardiolgy_text,
    handleClickLink
}) => {
    const filteredDiagnostics = sub_rythmo_diagnostics
        .filter(({ link }) => link !== null)
        .map(({ link }) => link);

    const filteredPathologies = sub_rythmo_pathologies
        .filter(({ link }) => link !== null)
        .map(({ link }) => link);

    return (
        <StyledRow>
            <LinkHover to={sub_rythmo_link.url}>{sub_rythmo_link.data.title[0].text}</LinkHover>
            <StyledLinks>
                <StyledLinksTitle>Tests Diagnostiques</StyledLinksTitle>
                <StyledLinksItems>
                    {filteredDiagnostics.map(({ uid, data }) => (
                        <LinkAnchor
                            key={uid}
                            to={sub_rythmo_link.url}
                            state={{ selectedDiagnosticUid: uid }}
                            anchor={`diagnostic-${uid}`}
                            offset={-110}
                            onClick={handleClickLink}
                            component={<LinkHover />}
                        >
                            {data.title[0].text}
                        </LinkAnchor>
                    ))}
                </StyledLinksItems>
            </StyledLinks>
            <StyledLinks>
                <StyledLinksTitle>Pathologies</StyledLinksTitle>
                <StyledLinksItems>
                    {filteredPathologies.map(({ uid, data }) => (
                        <LinkAnchor
                            key={uid}
                            to={sub_rythmo_link.url}
                            state={{ selectedPathologyUid: uid }}
                            anchor="pathologies"
                            onClick={handleClickLink}
                            component={<LinkHover />}
                        >
                            {data.title[0].text}
                        </LinkAnchor>
                    ))}
                </StyledLinksItems>
            </StyledLinks>
            <LinkAnchor
                to={sub_rythmo_link.url}
                anchor="telecardiolgy"
                onClick={handleClickLink}
                component={<LinkHover />}
            >
                {telecardiolgy_text}
            </LinkAnchor>
        </StyledRow>
    );
};

export default SubMenuRythmo;
