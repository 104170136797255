import React from 'react';
import scroller from 'react-scroll/modules/mixins/scroller';
import Link from './Link';
import EmitterManager from '../managers/EmitterManager';
import { useMedia } from '../hooks';
import { mqMobile } from '../hooks/useMedia';
import { theme } from '../styles';
import { PAGE_UPDATE } from '../core/events';

export const ANCHOR_DURATION = 1000;
export const ANCHOR_EASE = 'easeInOutQuin';

const LinkAnchor = ({ anchor, onClick, children, offset, component, ...props }) => {
    const isSamePage = typeof window !== 'undefined' && window.location.href.indexOf(props.to) > -1;
    const isMobile = useMedia(mqMobile, [true], false);

    const handleClick = event => {
        const options = {
            offset:
                offset + (isMobile ? -theme.sizes.headerHeightMobile : -theme.sizes.headerHeight)
        };

        if (isSamePage) {
            scroller.scrollTo(anchor, {
                duration: ANCHOR_DURATION,
                smooth: ANCHOR_EASE,
                ...options
            });
        } else {
            EmitterManager.once(PAGE_UPDATE, () => {
                scroller.scrollTo(anchor, options);
            });
        }

        if (onClick instanceof Function) {
            onClick(event);
        }
    };

    return React.cloneElement(component, { onClick: handleClick, ...props }, children);
};

LinkAnchor.defaultProps = {
    component: <Link />,
    offset: 0
};

export default LinkAnchor;
