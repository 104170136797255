import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { h2 } from '../styles/typography';
import { buttonReset } from '../styles/others';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import useKey from '../hooks/useKey';
import linkResolver from '../../config/linkResolver';

const getSearchResults = (query, lang) => {
    if (!query || !window.__LUNR__) return false;
    const lunrIndex = window.__LUNR__[lang];
    const normalizedQuery = query.replace(/(\^|\*|~)/g, '');
    const queryResults = lunrIndex.index.search(`${normalizedQuery}~2`); // you can  customize your search , see https://lunrjs.com/guides/searching.html
    return queryResults.map(({ ref }) => lunrIndex.store[ref]).slice(0, 6);
};

const Search = ({ lang, onValidation, shouldFocus }) => {
    const { allPageRythmology, allNewsPost, allPageNews, allPageWork } = useStaticQuery(
        graphql`
            query SearchBaseQuery {
                allPageRythmology: allPrismicPageRythmology {
                    edges {
                        node {
                            lang
                            uid
                            type
                            data {
                                title {
                                    text
                                }
                            }
                        }
                    }
                }
                allPageNews: allPrismicPageNews {
                    edges {
                        node {
                            lang
                            uid
                            type
                            data {
                                title {
                                    text
                                }
                            }
                        }
                    }
                }
                allPageWork: allPrismicPageWork {
                    edges {
                        node {
                            lang
                            uid
                            type
                            data {
                                title {
                                    text
                                }
                            }
                        }
                    }
                }
                allNewsPost: allPrismicNewsPost(
                    sort: { fields: [first_publication_date], order: DESC }
                ) {
                    edges {
                        node {
                            lang
                            uid
                        }
                    }
                }
            }
        `
    );

    const pageRythmology = allPageRythmology.edges.find(({ node }) => node.lang === lang);
    const pageNews = allPageNews.edges.find(({ node }) => node.lang === lang);
    const pageWork = allPageWork.edges.find(({ node }) => node.lang === lang);

    const newsPostsLocalized = allNewsPost.edges
        .filter(({ node }) => node.lang === lang)
        .map(({ node }) => node);

    const $input = useRef(null);

    const [search, setSearch] = useState({
        selected: 0,
        query: '',
        results: []
    });

    useEffect(() => {
        setSearch({ ...search, results: getSearchResults(search.query, 'fr'), selected: 0 });
    }, [search.query]);

    useEffect(() => {
        if ($input.current) {
            $input.current[shouldFocus ? 'focus' : 'blur']();
        }
    }, [shouldFocus]);

    useKey(
        key => {
            switch (key) {
                case 38:
                    return setSearch(prevState => ({
                        ...prevState,
                        selected: Math.max(prevState.selected - 1, 0)
                    }));
                case 40:
                    return setSearch(prevState => ({
                        ...prevState,
                        selected: Math.min(prevState.selected + 1, prevState.results.length - 1)
                    }));
                case 13:
                    return handleChoice();
            }
        },
        [40, 38, 13]
    );

    const getPageField = ({ type, title }) => {
        switch (type) {
            case 'news_post':
                return pageNews.node.data.title.text;
            case 'profile':
                return pageWork.node.data.title.text;
            case 'pathology':
                return pageRythmology.node.data.title.text;
            default:
                return title;
        }
    };

    const handleChoice = () => {
        setSearch(prevState => {
            const choice = prevState.results[prevState.selected];

            if (choice) {
                switch (choice.type) {
                    case 'news_post':
                        const index = newsPostsLocalized.findIndex(({ uid }) => uid === choice.uid);
                        const page = Math.floor(index / 2) + 1;
                        navigate(linkResolver(pageNews.node, page));
                        break;
                    case 'pathology':
                        navigate(linkResolver(pageRythmology.node));
                        break;
                    case 'profile':
                        navigate(linkResolver(pageWork.node));
                        break;
                    default:
                        navigate(linkResolver({ ...choice, lang }));
                        break;
                }

                if (onValidation instanceof Function) {
                    onValidation();
                }
            }

            return {
                ...prevState,
                query: ''
            };
        });
    };

    return (
        <Block>
            <StyledInput
                type="text"
                ref={$input}
                value={search.query}
                onChange={event => setSearch({ ...search, query: event.currentTarget.value })}
                placeholder="Chercher une pathologie, un article..."
            />
            <div>
                {search.results.length > 0 && (
                    <>
                        <StyledTop>
                            <div>Résultat</div>
                            <div>Page</div>
                        </StyledTop>
                        {search.results.map(({ uid, title, type }, index) => (
                            <StyledResult
                                key={`${type}_${uid}`}
                                aria-selected={search.selected === index}
                                onMouseEnter={() => setSearch({ ...search, selected: index })}
                                onClick={handleChoice}
                            >
                                <div>{title}</div>
                                <div>{getPageField({ type, title })}</div>
                            </StyledResult>
                        ))}
                    </>
                )}
            </div>
        </Block>
    );
};

const Block = styled.div`
    padding-left: ${sizeAsBase(2)};
    padding-right: ${sizeAsBase(2)};
    padding-top: ${props => props.theme.spacing.s60};
    padding-bottom: ${props => props.theme.spacing.s60};

    ${media.large`
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(2)};
    `}
`;

const StyledInput = styled.input`
    ${buttonReset}
    ${h2};
    width: 100%;
    color: ${props => props.theme.colors.black};
    border-bottom: 1px solid ${props => props.theme.colors.border};
    padding: ${props => props.theme.spacing.s10};
    margin-bottom: ${props => props.theme.spacing.s40};
`;

const StyledTop = styled.div`
    padding: ${props => props.theme.spacing.s10};
    display: flex;

    & > div {
        width: 50%;
    }

    font-size: ${props => props.theme.fontSize.xsmall};
    line-height: ${props => props.theme.lineHeight.xsmall};
    text-transform: uppercase;
    color: ${props => props.theme.colors.grey};
`;

const StyledResult = styled.a`
    padding: ${props => props.theme.spacing.s10};
    display: flex;
    cursor: pointer;

    & > div {
        width: 50%;

        &:last-child {
            color: ${props => props.theme.colors.grey};
        }
    }

    &[aria-selected='true'] {
        background: ${props => props.theme.colors.grey};

        & > div:last-child {
            color: ${props => props.theme.colors.white};
        }
    }
`;

export default Search;
