import React from 'react';
import styled from 'styled-components';
import { h3 } from '../styles/typography';
import { media } from '../styles';
import Rte from './Rte';

const StyledTitle = styled.h3`
    ${h3}
    font-weight: normal;
    margin-bottom: ${props => props.theme.spacing.s30};

    ${media.mobile`
        margin-bottom: ${props => props.theme.spacing.s20};
    `}
`;

const StyledRte = styled(Rte)`
    font-size: ${props => props.theme.fontSize.xsmall};
    line-height: ${props => props.theme.lineHeight.xsmall};
`;

const ScheduleList = ({ schedule_title, schedule }) => {
    return (
        <div>
            <StyledTitle>{schedule_title}</StyledTitle>
            <StyledRte dangerouslySetInnerHTML={{ __html: schedule.html }} />
        </div>
    );
};

export default ScheduleList;
