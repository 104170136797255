import React, { useState } from 'react';
import styled from 'styled-components';
import Link from './Link';
import { ArrowDown } from './Icons';
import { linkReset } from '../styles/others';
import { sizeAsBase } from '../styles/utils';
import BorderSeparator from './BorderSeparator';
import LinkAnchor from './LinkAnchor';

const StyledAction = styled.div`
    position: relative;
    display: block;
    padding: ${props => props.theme.spacing.s20} ${sizeAsBase(2)};

    ${ArrowDown} {
        position: absolute;
        top: 50%;
        right: ${props => props.theme.spacing.s30};
        transform: translateY(-50%) rotate(${props => (props.isOpen ? 180 : 0)}deg);
        font-size: 9px;
    }
`;

const StyledSub = styled.div`
    display: ${props => (props.isOpen ? 'block' : 'none')};
    padding: ${props => props.theme.spacing.s10} ${sizeAsBase(4)}
        ${props => props.theme.spacing.s30};
`;

const StyledSubLink = styled(Link)`
    ${linkReset};
    display: block;

    & + & {
        margin-top: 1em;
    }
`;

const SubMenuRythmoMobile = ({
    sub_rythmo_link,
    sub_rythmo_diagnostics,
    sub_rythmo_pathologies,
    onClickSubLink
}) => {
    const filteredDiagnostics = sub_rythmo_diagnostics
        .filter(({ link }) => link !== null)
        .map(({ link }) => link);

    const filteredPathologies = sub_rythmo_pathologies
        .filter(({ link }) => link !== null)
        .map(({ link }) => link);

    const [activeSub, setActiveSub] = useState(null);

    return (
        <div>
            <div>
                <StyledAction
                    isOpen={activeSub === 'diagnostics'}
                    onClick={() => setActiveSub(activeSub === 'diagnostics' ? null : 'diagnostics')}
                >
                    <span>Tests Diagnostiques</span>
                    <ArrowDown />
                </StyledAction>
                <StyledSub isOpen={activeSub === 'diagnostics'}>
                    {filteredDiagnostics.map(({ uid, data }) => (
                        <LinkAnchor
                            key={uid}
                            to={sub_rythmo_link.url}
                            state={{ selectedDiagnosticUid: uid }}
                            onClick={onClickSubLink}
                            component={<StyledSubLink />}
                            anchor="diagnostics"
                        >
                            {data.title[0].text}
                        </LinkAnchor>
                    ))}
                </StyledSub>
            </div>
            <BorderSeparator />
            <div>
                <StyledAction
                    isOpen={activeSub === 'pathologies'}
                    onClick={() => setActiveSub(activeSub === 'pathologies' ? null : 'pathologies')}
                >
                    <span>Pathologies</span>
                    <ArrowDown />
                </StyledAction>
                <StyledSub isOpen={activeSub === 'pathologies'}>
                    {filteredPathologies.map(({ uid, data }) => (
                        <LinkAnchor
                            key={uid}
                            to={sub_rythmo_link.url}
                            state={{ selectedPathologyUid: uid }}
                            onClick={onClickSubLink}
                            component={<StyledSubLink />}
                            anchor="pathologies"
                        >
                            {data.title[0].text}
                        </LinkAnchor>
                    ))}
                </StyledSub>
            </div>
            <BorderSeparator />
        </div>
    );
};

export default SubMenuRythmoMobile;
