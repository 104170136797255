import EventEmitter from 'eventemitter3';

class EmitterManager extends EventEmitter {
    constructor() {
        super(...arguments);
    }

    addEventListener() {
        this.on(...arguments);
    }

    removeEventListener() {
        this.removeListener(...arguments);
    }
}

export default new EmitterManager();
