import React, { createContext } from 'react';

/**
 * On ajoute la propriété `setName` à notre contexte
 */
export const ModalContext = createContext({
    modalsComponents: [],
    addModal: () => {},
    removeModal: () => {},
    hasModal: () => {}
});

/**
 * et on implémente une méthode dans notre `state`
 * qui va mettre à jour ce dernier avec la valeur passée en paramètre.
 * A noter qu'on peut aussi faire appel à des méthodes de notre
 * composant, mais on va faire simple pour l'exemple.
 */
class ModalProvider extends React.Component {
    state = {
        modalsComponents: [],
        addModal: modalComponent => {
            this.setState({ modalsComponents: [...this.state.modalsComponents, modalComponent] });
        },
        removeModal: key => {
            const modalsComponents = this.state.modalsComponents.filter(child => child.key !== key);
            this.setState({ modalsComponents });
        },
        hasModal: key => this.state.modalsComponents.find(child => child.key === key) !== undefined
    };

    render() {
        return (
            // Ici, rien ne change !
            <ModalContext.Provider value={this.state}>{this.props.children}</ModalContext.Provider>
        );
    }
}

export default ModalProvider;

/**
 * A la suite de notre classe `ModalProvider`, on créé notre HOC
 * qui se chargera d'injecter les propriétés de notre contexte
 * à n'importe quel composant qui l'appellera
 */
const withModal = Component =>
    function WidthModal(props) {
        return (
            <ModalContext.Consumer>
                {store => <Component {...props} {...store} />}
            </ModalContext.Consumer>
        );
    };

export { withModal };
