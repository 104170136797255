import { css } from 'styled-components';
import { sizeAsBase, sizeAsMax } from './utils';
import { media } from '.';

export const linkReset = css`
    font: inherit;
    border-radius: 0;
    appearance: none;
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
    cursor: pointer;
    outline: none;
    color: inherit;
    text-decoration: none;
`;

export const buttonReset = css`
    font: inherit;
    border-radius: 0;
    appearance: none;
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
    cursor: pointer;
    outline: none;
    color: inherit;
    text-decoration: none;
`;

export const gutterBackground = css`
    &:after,
    &:before {
        content: '';
        display: block;
        width: ${sizeAsBase(3)};
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        box-shadow: inset -1px 0 0 0 ${props => props.theme.colors.grey};
        background: inherit;
    }

    &:after {
        left: 100%;
        box-shadow: inset 1px 0 0 0 ${props => props.theme.colors.grey};
    }

    ${media.large`
        &:after,
        &:before {
            width: ${sizeAsMax(3)}
        }
    `}
`;
