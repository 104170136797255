import React from 'react';
import styled from 'styled-components';

const StyledBurger = styled.div`
    position: relative;
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: 10px;

    i {
        display: block;
        width: 20px;
        height: 2px;
        border-radius: 1px;
        background: ${props => props.theme.colors.red};
        position: absolute;
        top: 50%;
        left: 50%;
        transition: transform 0.2s ${props => props.theme.easings.easeInOutQuart};
        transform: translate(-50%, -50%);

        &:first-child {
            transform: translate(-50%, -50%) translateY(${props => (props.isOpen ? '0' : '-4px')})
                rotate(${props => (props.isOpen ? '45deg' : '0')});
        }

        &:last-child {
            transform: translate(-50%, -50%) translateY(${props => (props.isOpen ? '0' : '4px')})
                rotate(${props => (props.isOpen ? '-45deg' : '0')});
        }
    }
`;

const Burger = props => {
    return (
        <StyledBurger {...props}>
            <i />
            <i />
        </StyledBurger>
    );
};

export default Burger;
