import styled, { css } from 'styled-components';
import IconPlay from '-!svg-react-loader!../assets/svgs/play.svg';
import IconLogo from '-!svg-react-loader!../assets/svgs/logo.svg';
import IconLogoMobile from '-!svg-react-loader!../assets/svgs/logo-mobile.svg';
import IconBackTop from '-!svg-react-loader!../assets/svgs/back-top.svg';
import IconArrowDown from '-!svg-react-loader!../assets/svgs/arrow-down.svg';
import IconClose from '-!svg-react-loader!../assets/svgs/close.svg';
import IconSearch from '-!svg-react-loader!../assets/svgs/search.svg';

const iconCSS = css`
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
`;

export const Play = styled(IconPlay)`
    ${iconCSS}
    width: 1em;
`;

export const Logo = styled(IconLogo)`
    ${iconCSS}
    width: ${135.1 / 16.2}em;
`;

export const LogoMobile = styled(IconLogoMobile)`
    ${iconCSS}
    width: ${180 / 20.97}em;
`;

export const BackTop = styled(IconBackTop)`
    ${iconCSS}
    width: ${8.84 / 13.91}em;
`;

export const ArrowDown = styled(IconArrowDown)`
    ${iconCSS}
    width: ${18.39 / 9.9}em;
`;

export const Close = styled(IconClose)`
    ${iconCSS}
    width: ${22.11 / 21.79}em;
`;

export const Search = styled(IconSearch)`
    ${iconCSS}
    width: ${31.4 / 27.07}em;
`;
