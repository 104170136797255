import React, { createContext } from 'react';

/**
 * On ajoute la propriété `setName` à notre contexte
 */
export const MapsContext = createContext({
    isScriptLoaded: false,
    setScriptLoaded: () => {}
});

/**
 * et on implémente une méthode dans notre `state`
 * qui va mettre à jour ce dernier avec la valeur passée en paramètre.
 * A noter qu'on peut aussi faire appel à des méthodes de notre
 * composant, mais on va faire simple pour l'exemple.
 */
class MapsProvider extends React.Component {
    state = {
        isScriptLoaded: false,
        setScriptLoaded: isScriptLoaded => this.setState({ isScriptLoaded }),
    };

    render() {
        return (
            // Ici, rien ne change !
            <MapsContext.Provider value={this.state}>{this.props.children}</MapsContext.Provider>
        );
    }
}

export default MapsProvider;

/**
 * A la suite de notre classe `MapsProvider`, on créé notre HOC
 * qui se chargera d'injecter les propriétés de notre contexte
 * à n'importe quel composant qui l'appellera
 */
const withMaps = Component =>
    function withMaps(props) {
        return (
            <MapsContext.Consumer>
                {store => <Component {...props} {...store} />}
            </MapsContext.Consumer>
        );
    };

export { withMaps };
