import React from 'react';
import styled from 'styled-components';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import Row from './Row';

const StyledSubMenu = styled.div`
    position: absolute;
    top: 100%;
    left: ${sizeAsBase(-3)};
    right: ${sizeAsBase(-3)};
    z-index: 2;
    box-shadow: inset 0 -1px 0 0 ${props => props.theme.colors.border};
    background: ${props => props.theme.colors.white};
    display: ${props => (props.isOpen ? 'block' : 'none')};

    ${media.large`
        left: ${sizeAsMax(-3)};
        right: ${sizeAsMax(-3)};
    `}
`;

const StyledRow = styled(Row)`
    min-height: 480px;
`;

const SubMenu = ({ children, isOpen }) => {
    return (
        <StyledSubMenu isOpen={isOpen}>
            <StyledRow>{children}</StyledRow>
        </StyledSubMenu>
    );
};

SubMenu.defaultProps = {
    isOpen: false
};

export default SubMenu;
