import React, { useState } from 'react';
import styled from 'styled-components';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import { withMaps } from '../store/MapsProvider';

const StyledMap = styled.div`
    width: 100%;
    height: 100%;

    [id*='map'] {
        width: 100%;
        height: 100%;
    }
`;

const StyledInfoWindow = styled.div`
    padding: 20px;
    background: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fontSize.normal};
    line-height: ${props => props.theme.lineHeight.normal};
`;

const MapPlaces = ({ isScriptLoaded, className, places, ...mapProps }) => {
    const [center, setCenter] = useState({ lat: 48.864716, lng: 2.349014 });
    const [infoWindow, setInfoWindow] = useState(null);

    const others = {
        center,
        zoom: 12,
        options: {
            disableDefaultUI: true
        },
        ...mapProps
    };

    const handleLoad = () => {
        /* eslint-disable */
        var bound = new google.maps.LatLngBounds();
        places.forEach(({ coordinates }) =>
            bound.extend(new google.maps.LatLng(coordinates.lat, coordinates.lng))
        );
        setCenter(bound.getCenter());
        /* eslint-enable */
    };

    const handleInfoWindowVisibilityChange = () => {
        setInfoWindow(null);
    };

    const handleClickMarker = coord => {
        const place = places.find(({ coordinates }) => coord === coordinates);

        setInfoWindow(
            <InfoWindow
                position={place.coordinates}
                onCloseClick={handleInfoWindowVisibilityChange}
            >
                <StyledInfoWindow>
                    <p>
                        <strong>{place.name}</strong>
                    </p>
                    <div dangerouslySetInnerHTML={{ __html: place.address.html }} />
                </StyledInfoWindow>
            </InfoWindow>
        );
    };

    return (
        <StyledMap className={className}>
            {isScriptLoaded && (
                <GoogleMap id="work-map" {...others} onLoad={handleLoad}>
                    {places.map(({ name, coordinates }) => (
                        <Marker
                            key={name}
                            position={coordinates}
                            onClick={() => handleClickMarker(coordinates)}
                        />
                    ))}
                    {infoWindow}
                </GoogleMap>
            )}
        </StyledMap>
    );
};

export default withMaps(MapPlaces);
