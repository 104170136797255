import lax from 'lax.js';
import { useEffect, useRef } from 'react';

function useLax() {
    if (typeof window !== 'undefined') {
        lax.setup();
    }

    useEffect(() => {
        var updateLax = function updateLax() {
            // console.log(window.scrollY);
            lax.update(window.scrollY || window.pageYOffset);
        };

        var updateLaxElements = function updateLaxElements() {
            lax.updateElements();
        };

        if (typeof window !== 'undefined') {
            document.addEventListener('scroll', updateLax, false);
            window.addEventListener('resize', updateLaxElements, false);
        }

        updateLax();

        return function() {
            if (typeof window !== 'undefined') {
                document.removeEventListener('scroll', updateLax);
                window.removeEventListener('resize', updateLaxElements);
            }
        };
    }, []);
}

function useLaxElement() {
    var ref = useRef();
    useEffect(() => {
        var currentNode = ref.current;
        lax.addElement(currentNode);
        return function() {
            lax.removeElement(currentNode);
        };
    }, []);
    return ref;
}

export { useLax, useLaxElement };
export default useLax;
