import { css } from 'styled-components';
import media from './media';

export const h0 = css`
    font-size: 86px;
    line-height: 100px;
    font-weight: ${props => props.theme.fontWeight.light};
    color: ${props => props.theme.colors.blue};
`;

export const h1 = css`
    font-size: ${props => props.theme.fontSize.h1};
    line-height: ${props => props.theme.lineHeight.h1};

    ${media.mobile`
        font-size: ${props => props.theme.fontSize.h1mob};
        line-height: ${props => props.theme.lineHeight.h1mob};
    `}
`;

export const h2 = css`
    font-size: ${props => props.theme.fontSize.h2};
    line-height: ${props => props.theme.lineHeight.h2};

    ${media.mobile`
        font-size: ${props => props.theme.fontSize.h2mob};
        line-height: ${props => props.theme.lineHeight.h2mob};
    `}
`;

export const h3 = css`
    font-size: ${props => props.theme.fontSize.h3};
    line-height: ${props => props.theme.lineHeight.h3};
    font-weight: bold;

    ${media.mobile`
        font-size: ${props => props.theme.fontSize.h3mob};
        line-height: ${props => props.theme.lineHeight.h3mob};
    `}
`;

export const panelTitle = css`
    font-size: ${props => props.theme.fontSize.small};
    line-height: ${props => props.theme.lineHeight.small};
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.4px;
`;

export const xsmall = css`
    font-size: ${props => props.theme.fontSize.xsmall};
    line-height: ${props => props.theme.lineHeight.xsmall};
    text-transform: uppercase;
    font-weight: bold;
`;

export const author = css`
    font-size: ${props => props.theme.fontSize.xsmall};
    line-height: ${props => props.theme.lineHeight.xsmall};
    font-weight: bold;
    color: ${props => props.theme.colors.red};
`;
