import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import scroll from 'react-scroll/modules/mixins/animate-scroll';
import Row from './Row';
import { Logo, BackTop } from './Icons';
import LinkLarge from './LinkLarge';
import Rte from './Rte';
import BorderSeparator from './BorderSeparator';
import Link from './Link';
import PlacesList from './PlacesList';
import ScheduleList from './ScheduleList';
import { media } from '../styles';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { h3 } from '../styles/typography';
import { linkReset, buttonReset } from '../styles/others';
import LinkHover from './LinkHover';

const StyledFooter = styled.footer`
    position: relative;
    background: ${props => props.theme.colors.font};
    color: ${props => props.theme.colors.white};
    overflow: hidden;
    max-width: 100%;

    ${Logo} {
        font-size: 25px;
        display: block;
        margin-top: 7px;
        color: ${props => props.theme.colors.white};
    }
`;

const StyledGrid = styled(Row)`
    padding: ${props => props.theme.spacing.s40} ${sizeAsBase(2)};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: ${sizeAsBase(2)};

    & > div {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: -${props => props.theme.spacing.s40};
            bottom: -${props => props.theme.spacing.s40};
            left: ${sizeAsBase(-1)};
            width: 1px;
            background: ${props => props.theme.colors.black};
        }

        &:first-child:before {
            display: none;
        }
    }

    ${media.large`
        padding-left: ${sizeAsMax(2)};
        padding-right: ${sizeAsMax(2)};
        grid-column-gap: ${sizeAsMax(2)};
    `}

    ${media.mobile`
        padding-top: ${props => props.theme.spacing.s30};
        padding-bottom: ${props => props.theme.spacing.s30};
        grid-template-columns: 1fr;
        grid-row-gap: ${props => props.theme.spacing.s60};

        & > div {
            &:before {
                top: -${props => props.theme.spacing.s30};
                bottom: auto;
                left: ${sizeAsBase(-5)};
                right: ${sizeAsBase(-5)};
                width: auto;
                height: 1px;
            }
            &:nth-child(1) { display: none; }
            &:nth-child(2) { order: 2; }
            &:nth-child(3) { order: 3; }
            &:nth-child(4) {
                order: 1;
                &:before {
                    display: none;
                }
            }
        }
    `}
`;

const StyledColLink = styled.div`
    display: flex;
    flex-direction: column;
    a {
        ${linkReset};
        ${h3};
        font-weight: normal;

        & + a {
            margin-top: ${props => props.theme.spacing.s20};
        }
    }
`;

const SmallRow = css`
    display: flex;
    align-items: center;
    height: ${props => props.theme.spacing.s60};
    font-size: ${props => props.theme.fontSize.xsmall};
    line-height: ${props => props.theme.lineHeight.xsmall};
    padding: 0 ${sizeAsBase(2)};

    a {
        ${linkReset};
        & + a {
            margin-left: ${sizeAsBase(5)};
        }
    }

    ${media.large`
        padding: 0 ${sizeAsMax(2)};

        a + a {
            margin-left; ${sizeAsMax(5)};
        }
    `}
`;

const StyledBackTop = styled(Row)`
    ${SmallRow}

    button {
        ${buttonReset};

        ${BackTop} {
            margin-right: 15px;
        }
    }
`;
const StyledNav = styled(Row)`
    ${SmallRow}
`;

const Footer = ({ pageContext }) => {
    const { footers, contacts } = useStaticQuery(
        graphql`
            query SiteFooters {
                footers: allPrismicFooter {
                    edges {
                        node {
                            id: prismicId
                            lang
                            data {
                                links {
                                    link {
                                        uid
                                        url
                                        data {
                                            title {
                                                text
                                            }
                                        }
                                    }
                                }
                                bottom_links {
                                    link {
                                        uid
                                        url
                                        data {
                                            title {
                                                text
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                contacts: allPrismicContact {
                    edges {
                        node {
                            uid
                            lang
                            data {
                                places_title
                                places {
                                    name
                                    address {
                                        html
                                    }
                                }
                                schedule_title
                                schedule {
                                    html
                                }
                            }
                        }
                    }
                }
            }
        `
    );

    const langFooter = footers.edges.find(({ node }) => node.lang === pageContext.lang).node;
    const langContact = contacts.edges.find(({ node }) => node.lang === pageContext.lang).node;

    const filteredLinks = langFooter.data.links
        .filter(({ link }) => link !== null)
        .map(({ link }) => link);

    const filteredBottomLinks = langFooter.data.bottom_links
        .filter(({ link }) => link !== null)
        .map(({ link }) => link);

    return (
        <StyledFooter>
            <StyledBackTop>
                <button
                    onClick={() =>
                        scroll.scrollToTop({
                            duration: 1000,
                            smooth: 'easeInOutQuint'
                        })
                    }
                >
                    <BackTop />
                    Retour en haut de page
                </button>
            </StyledBackTop>
            <BorderSeparator color="black" />
            <StyledGrid>
                <div>
                    <Link to="/">
                        <Logo />
                    </Link>
                </div>
                <PlacesList {...langContact.data} />
                <ScheduleList {...langContact.data} />
                <StyledColLink>
                    {filteredLinks.map(({ uid, url, data }) => (
                        <LinkLarge key={uid} to={url}>
                            {data.title[0].text}
                        </LinkLarge>
                    ))}
                </StyledColLink>
            </StyledGrid>
            <BorderSeparator color="black" />
            <StyledNav>
                {filteredBottomLinks.map(({ uid, url, data }) => (
                    <LinkHover key={uid} to={url}>
                        {data.title[0].text}
                    </LinkHover>
                ))}
            </StyledNav>
        </StyledFooter>
    );
};

export default Footer;
