import { useEffect } from 'react';

function useKey(callback, keyCodes) {
    function handleEvent(event) {
        const index = keyCodes.indexOf(event.keyCode);
        return callback(keyCodes[index], event);
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('keyup', handleEvent);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('keyup', handleEvent);
            }
        };
    }, []);
}

export default useKey;
