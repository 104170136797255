import React from 'react';
import styled from 'styled-components';
import Link from './Link';
import { h2 } from '../styles/typography';
import { linkReset } from '../styles/others';

const StyledLink = styled(Link)`
    ${linkReset}
    ${h2}
    transition: color 0.6s ${props => props.theme.easings.easeOutQuart};

    &:hover {
        color: ${props => props.theme.colors.red};
    }
`;

const LinkLarge = ({ children, ...others }) => <StyledLink {...others}>{children}</StyledLink>;

export default LinkLarge;
