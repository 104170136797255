import React from 'react';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import { withModal } from '../store/ModalProvider';
import { PoseGroup } from 'react-pose';
import { media } from '../styles';
import { Helmet } from 'react-helmet';
import { withMaps } from '../store/MapsProvider';
import { LoadScript } from '@react-google-maps/api';
import PageTransition from './PageTransition';
import EmitterManager from '../managers/EmitterManager';
import { PAGE_UPDATE, PAGE_VISIBLE } from '../core/events';

const GlobalStyle = createGlobalStyle`
    html, body {
        font-size: ${props => props.theme.fontSize.base};
        line-height: ${props => props.theme.lineHeight.base};
        background: ${props => props.theme.colors.background};
        color: ${props => props.theme.colors.font};
        width: 100%;
        -webkit-font-smoothing: antialiased;
    }

    html.no-scroll,
    html.no-scroll body {
        overflow: hidden;
    }

    ${media.mobile`
        body {
            font-size: ${props => props.theme.fontSize.mobile};
            line-height: ${props => props.theme.lineHeight.mobile};
        }
    `}
`;

const StyledMain = styled.div`
    padding-top: ${props => props.theme.sizes.headerHeight}px;
    max-width: ${props => props.theme.sizes.mainMaxWidth - props.theme.sizes.mainMaxGutter}px;
    margin-left: auto;
    margin-right: auto;
    background: ${props => props.theme.colors.white};

    ${media.mobile`
        padding-top: ${props => props.theme.sizes.headerHeightMobile}px;
    `}
`;

const Layout = withMaps(
    withModal(({ children, location, setScriptLoaded, modalsComponents, ...others }) => {
        const handlePageUpdate = () => {
            if (typeof window !== 'undefined') {
                window.scrollTo(0, 0);
            }

            EmitterManager.emit(PAGE_UPDATE, { type: PAGE_UPDATE });
        };

        const handlePoseComplete = state => {
            if (state === 'enter') {
                EmitterManager.emit(PAGE_VISIBLE, { type: PAGE_VISIBLE });
            }
        };

        const hasLang = others.pageContext && others.pageContext.lang;

        return (
            <StyledMain>
                <Helmet
                    htmlAttributes={{
                        lang: hasLang && others.pageContext.lang.split('-')[0]
                    }}
                >
                    <title>Les Rythmologues</title>
                    {/* eslint-disable */}
                    <link rel="apple-touch-icon" sizes="180x180" href="/images/favicons/apple-touch-icon.png" />
                    <link rel="icon" type="image/png" sizes="32x32" href="/images/favicons/favicon-32x32.png" />
                    <link rel="icon" type="image/png" sizes="16x16" href="/images/favicons/favicon-16x16.png" />
                    <link rel="manifest" href="/images/favicons/site.webmanifest" />
                    <link rel="mask-icon" href="/images/favicons/safari-pinned-tab.svg" color="#5bbad5" />
                    <link rel="shortcut icon" href="/images/favicons/favicon.ico" />
                    <meta name="msapplication-TileColor" content="#ffffff" />
                    <meta name="msapplication-config" content="/images/favicons/browserconfig.xml" />
                    <meta name="theme-color" content="#ffffff" />
                    {/* eslint-enable */}
                </Helmet>
                <GlobalStyle />
                <LoadScript
                    id="script-loader"
                    googleMapsApiKey="AIzaSyD0sO2XtbcZjY6sMV7HJx7taZkOhtU2wIw"
                    loadingElement={<div />}
                    onLoad={() => setScriptLoaded(true)}
                />
                {hasLang && <Header {...others} />}
                <PoseGroup animateOnMount={true} onRest={handlePageUpdate} flipMove={false}>
                    <PageTransition
                        key={location.pathname}
                        style={{ opacity: 0 }}
                        onPoseComplete={handlePoseComplete}
                    >
                        {children}
                    </PageTransition>
                </PoseGroup>
                {hasLang && <Footer {...others} />}
                <PoseGroup>{modalsComponents}</PoseGroup>
            </StyledMain>
        );
    })
);

export default Layout;
