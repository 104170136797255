import posed from 'react-pose';

const transitionDuration = 300;
const transitionDelay = 350;

export default posed.div({
    init: {
        opacity: 0
    },
    enter: {
        opacity: 1,
        transition: { from: 0, to: 1, duration: transitionDuration },
        delay: transitionDelay
    },
    exit: {
        opacity: 0,
        transition: { duration: transitionDuration }
    }
});
