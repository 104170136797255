import React from 'react';
import styled from 'styled-components';
import Row from './Row';
import PlacesList from './PlacesList';
import ScheduleList from './ScheduleList';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import MapPlaces from './MapPlaces';

const StyledRow = styled(Row)`
    position: relative;
    min-height: 480px;
`;

const StyledCols = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${sizeAsBase(2)};
    margin-left: ${sizeAsBase(2)};
    width: ${sizeAsBase(6 * 3 + 2 * 5)};
    padding: ${props => props.theme.spacing.s40} 0;

    ${media.large`
        margin-left: ${sizeAsMax(2)};
        grid-column-gap: ${sizeAsMax(2)};
        width: ${sizeAsMax(6 * 3 + 2 * 5)};
    `}
`;

const StyledMapWrapper = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: ${sizeAsBase(30)};

    ${media.large`
        width: ${sizeAsMax(30)};
    `}
`;

const SubMenuContact = ({ ...others }) => {
    return (
        <StyledRow>
            <StyledCols>
                <PlacesList {...others} />
                <ScheduleList {...others} />
            </StyledCols>
            <StyledMapWrapper>
                <MapPlaces places={others.places} />
            </StyledMapWrapper>
        </StyledRow>
    );
};

export default SubMenuContact;