// import React from 'react';
import styled from 'styled-components';
import { h2, h1, h3 } from '../styles/typography';
import { linkReset } from '../styles/others';
import { transitionLinkColor } from './LinkHover';

const Rte = styled.div`
    h1 {
        ${h1};
        margin-bottom: ${props => props.theme.spacing.s40};
    }

    h2 {
        ${h2};
        margin-bottom: ${props => props.theme.spacing.s40};
    }

    h3 {
        ${h3};
        margin-bottom: ${props => props.theme.spacing.s40};
    }

    * + h1,
    * + h2,
    * + h3 {
        margin-top: ${props => props.theme.spacing.s80};
    }

    ul {
        list-style: disc inside;
    }

    ol {
        list-style: decimal-leading-zero inside;
    }

    p + ul,
    p + ol,
    p + p,
    ul + ul,
    ul + ol,
    ul + p,
    ol + ul,
    ol + ol,
    ol + p {
        margin-top: 1em;
    }

    a {
        ${linkReset};
        ${transitionLinkColor};
        cursor: pointer;

        &:hover {
            color: ${props => props.theme.colors.red};
        }
    }
`;

export default Rte;

export const RteMedicalCare = styled(Rte)`
    h3 {
        ${h2}
        font-weight: ${props => props.theme.fontWeight.light};
    }
`;
