// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-home-js": () => import("/opt/build/repo/src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-work-js": () => import("/opt/build/repo/src/templates/work.js" /* webpackChunkName: "component---src-templates-work-js" */),
  "component---src-templates-rythmology-js": () => import("/opt/build/repo/src/templates/rythmology.js" /* webpackChunkName: "component---src-templates-rythmology-js" */),
  "component---src-templates-research-js": () => import("/opt/build/repo/src/templates/research.js" /* webpackChunkName: "component---src-templates-research-js" */),
  "component---src-templates-news-js": () => import("/opt/build/repo/src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-medicalcare-js": () => import("/opt/build/repo/src/templates/medicalcare.js" /* webpackChunkName: "component---src-templates-medicalcare-js" */),
  "component---src-templates-simple-js": () => import("/opt/build/repo/src/templates/simple.js" /* webpackChunkName: "component---src-templates-simple-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

