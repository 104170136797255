import React from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';

export const rowShadowBlackCSS = css`
    box-shadow: 1px 0 0 0 ${props => rgba(props.theme.colors.black, 0.14)},
        -1px 0 0 0 ${props => rgba(props.theme.colors.black, 0.14)};
`;

export const rowShadowGreyCSS = css`
    box-shadow: 1px 0 0 0 ${props => props.theme.colors.grey},
        -1px 0 0 0 ${props => props.theme.colors.grey};
`;

export const rowCSS = css`
    ${rowShadowBlackCSS};
    position: relative;
    box-sizing: border-box;
    width: ${sizeAsBase(62)};
    max-width: 1550px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    ${media.large`
        width: ${sizeAsMax(62)};
    `}
`;

const StyledRow = styled.div`
    ${rowCSS};
`;

const Row = ({ children, ...others }) => <StyledRow {...others}>{children}</StyledRow>;

export default Row;
