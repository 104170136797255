import theme from './theme';

export function sizeAsBase(numberOfCols) {
    return `${(100 / 68) * numberOfCols}vw`;
}

export function sizeAsMax(numberOfCols) {
    return `${((theme.sizes.mainMaxWidth - theme.sizes.mainMaxGutter) / 68) * numberOfCols}px`;
}

export function sizeFromRow(numberOfPixels) {
    return `${(numberOfPixels / 1240) * 100}%`;
}
