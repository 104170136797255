import React from 'react';
import styled from 'styled-components';
import { h3 } from '../styles/typography';
import { media } from '../styles';
import Rte from './Rte';

const StyledTitle = styled.h3`
    ${h3}
    font-weight: normal;
    margin-bottom: ${props => props.theme.spacing.s30};

    ${media.mobile`
        margin-bottom: ${props => props.theme.spacing.s20};
    `}
`;

const StyledRte = styled(Rte)`
    font-size: ${props => props.theme.fontSize.xsmall};
    line-height: ${props => props.theme.lineHeight.xsmall};

    & + & {
        margin-top: 1em;
    }
`;

const PlacesList = ({ places_title, places }) => {
    places = places.filter(place => place !== null);

    return (
        <div>
            <StyledTitle>{places_title}</StyledTitle>
            {places.map(({ address, name }) => (
                <StyledRte key={name}>
                    {name}
                    <div dangerouslySetInnerHTML={{ __html: address.html }} />
                </StyledRte>
            ))}
        </div>
    );
};

export default PlacesList;
