import { rem, rgba } from 'polished';
import easings from './easings';

const theme = {
    sizes: {
        headerHeight: 80,
        headerHeightMobile: 40,
        mainMaxWidth: 1700,
        mainMaxGutter: 150
    },
    zIndex: {
        modal: 12,
        header: 11
    },
    colors: {
        background: '#ECECEC',
        black: '#000000',
        white: '#ffffff',
        font: '#010F22',
        blue: '#012352',
        blueLight: '#E5F2FF',
        red: '#FD664D',
        grey: '#DADADA',
        greyLight: '#F2F0EE',
        greyLightAlt: '#F5F5F5',
        border: rgba('#000000', 0.14)
    },
    fontWeight: {
        light: 300,
        normal: 'normal',
        medium: 'bold'
    },
    fontSize: {
        base: '20px',
        mobile: rem(16, 20),
        h1: rem(52, 20),
        h1mob: rem(40, 20),
        h2: rem(40, 20),
        h2mob: rem(28, 20),
        h3: rem(24, 20),
        h3mob: rem(20, 20),
        normal: rem(20, 20),
        small: rem(14, 20),
        xsmall: rem(12, 20)
    },
    lineHeight: {
        base: '32px',
        mobile: rem(24, 20),
        h1: rem(66, 20),
        h1mob: rem(48, 20),
        h2: rem(54, 20),
        h2mob: rem(38, 20),
        h3: rem(36, 20),
        h3mob: rem(30, 20),
        normal: rem(32, 20),
        small: rem(18, 20),
        xsmall: rem(20, 20)
    },
    spacing: {
        s10: rem(10, 20),
        s15: rem(15, 20),
        s20: rem(20, 20),
        s30: rem(30, 20),
        s40: rem(40, 20),
        s50: rem(50, 20),
        s60: rem(60, 20),
        s70: rem(70, 20),
        s80: rem(80, 20),
        s100: rem(100, 20),
        s110: rem(100, 20),
        s120: rem(120, 20),
        s140: rem(140, 20),
        s160: rem(160, 20)
    },
    easings
};

export default theme;
