module.exports = function linkResolver(document, page = 1) {
    switch (document.type) {
        case 'page_home':
            return `/${document.lang}/`;
        case 'page_news':
            const pageSlug = page > 1 ? `${page}/` : '';
            return `/${document.lang}/${document.uid}/${pageSlug}`;
        default:
            return `/${document.lang}/${document.uid}/`;
    }
};
