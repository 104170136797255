import { css } from 'styled-components';
import { theme } from '.';

export const maxWidth = {
    tabletLandscape: 1279,
    tabletPortrait: 1023,
    mobile: 767
};

export const minWidth = {
    large: theme.sizes.mainMaxWidth - theme.sizes.mainMaxGutter
};

// Iterate through the sizes and create a media template
let media = Object.keys(maxWidth).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (max-width: ${maxWidth[label]}px) {
            ${css(...args)}
        }
    `;

    return acc;
}, {});

media = Object.keys(minWidth).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (min-width: ${minWidth[label]}px) {
            ${css(...args)}
        }
    `;

    return acc;
}, media);

export default media;
