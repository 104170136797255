import React from 'react';
import styled from 'styled-components';
import { sizeAsBase } from '../styles/utils';
import Row from './Row';
import Link from './Link';
import { h2 } from '../styles/typography';
import { linkReset } from '../styles/others';
import BorderSeparator from './BorderSeparator';
import SubMenuRythmoMobile from './SubMenuRythmoMobile';
import { useWindowSize } from '../hooks';
import { theme } from '../styles';
import PlacesList from './PlacesList';
import ScheduleList from './ScheduleList';

const StyledMenuMobile = styled.div`
    position: absolute;
    top: 100%;
    left: ${sizeAsBase(-3)};
    right: ${sizeAsBase(-3)};
    padding: 0 ${sizeAsBase(3)};
    height: 100vh;
    background: ${props => props.theme.colors.white};
    display: ${props => (props.isOpen ? 'block' : 'none')};
`;

const StyledRow = styled(Row)`
    height: 100%;
    overflow-y: scroll;
`;

const StyledLinkTop = styled(Link)`
    ${linkReset};
    ${h2};
    display: block;
    padding: ${props => props.theme.spacing.s20} ${sizeAsBase(2)};
`;

const StyledCols = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: ${props => props.theme.spacing.s20} ${sizeAsBase(4)};
    grid-column-gap: ${props => props.theme.spacing.s20};
    box-shadow: inset 0 1px 0 0 ${props => props.theme.colors.grey};
`;

const MenuMobile = ({ pages, isOpen, ...others }) => {
    const { innerHeight } = useWindowSize();

    return (
        <StyledMenuMobile
            isOpen={isOpen}
            style={{ height: innerHeight - theme.sizes.headerHeightMobile }}
        >
            <StyledRow>
                {pages.map(({ page }) => (
                    <div key={page.id}>
                        <StyledLinkTop to={page.url} onClick={others.onClickSubLink}>
                            {page.data.title[0].text}
                        </StyledLinkTop>
                        <BorderSeparator />
                        {page.type === 'page_rythmology' && <SubMenuRythmoMobile {...others} />}
                    </div>
                ))}
                <StyledLinkTop>Contact</StyledLinkTop>
                <StyledCols>
                    <PlacesList {...others} />
                    <ScheduleList {...others} />
                </StyledCols>
            </StyledRow>
        </StyledMenuMobile>
    );
};

export default MenuMobile;
