import React from 'react';
import styled, { css } from 'styled-components';
import Link from './Link';
import { linkReset } from '../styles/others';

export const transitionLinkColor = css`
    transition: color 0.6s ${props => props.theme.easings.easeOutQuart};
`;

const LinkHover = styled(Link)`
    ${linkReset};
    ${transitionLinkColor};
    cursor: pointer;

    &:hover {
        color: ${props => props.theme.colors.red};
    }
`;

export default LinkHover;
