import React, { useState } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Link from './Link';
import LinkHover from './LinkHover';
import { Logo, Search as SearchIcon } from './Icons';
import Search from './Search';
import Row from './Row';
import { sizeAsBase, sizeAsMax } from '../styles/utils';
import { media } from '../styles';
import { linkReset, buttonReset } from '../styles/others';
import SubMenu from './SubMenu';
import SubMenuRythmo from './SubMenuRythmo';
import SubMenuContact from './SubMenuContact';
import LangSwitch from './LangSwitch';
import Burger from './Burger';
import useMedia, { mqTabletLandscape } from '../hooks/useMedia';
import MenuMobile from './MenuMobile';

const StyledHeader = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: ${props => props.theme.sizes.headerHeight}px;
    z-index: ${props => props.theme.zIndex.header};
    max-width: ${props => props.theme.sizes.mainMaxWidth - props.theme.sizes.mainMaxGutter}px;
    background: ${props => props.theme.colors.white};
    margin-left: auto;
    margin-right: auto;
    box-shadow: inset 0 -1px 0 0 ${props => props.theme.colors.border};
    width: 100%;

    ${Logo} {
        font-size: 25px;
        display: block;
        padding-top: 2px;
        color: ${props => props.theme.colors.red};
    }

    ${media.mobile`
        height: ${props => props.theme.sizes.headerHeightMobile}px;

        ${Logo} {
            font-size: 20px;
            padding-top: 0;
        }
    `}
`;

const StyledWrapper = styled(Row)`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding-left: ${sizeAsBase(2)};
    overflow: initial;

    & > a {
        display: block;
    }+ a

    ${media.large`
        padding-left: ${sizeAsMax(2)};
    `}
`;

const StyledNav = styled.nav`
    margin-left: auto;

    ${LinkHover} {
        &:last-child {
            margin-right: ${sizeAsBase(1.5)};
        }
    }

    ${LinkHover} + ${LinkHover} {
        margin-left: ${sizeAsBase(3)};
    }

    a {
        ${linkReset};

        & + a {
            margin-left: ${sizeAsBase(3)};
        }

        &:last-child {
            margin-right: ${sizeAsBase(1.5)};
        }
    }

    ${media.large`
        a {
            & + a {
                margin-left: ${sizeAsMax(2)};
            }

            &:last-child {
                margin-right: ${sizeAsBase(1.5)};
            }
        }
    `}
`;

const StyledSearch = styled.button`
    ${buttonReset}
    height: 100%;
    width: ${props => props.theme.spacing.s80};
    background: ${props => props.theme.colors.red};
    color: ${props => props.theme.colors.white};
    box-shadow: inset 0 -1px 0 0 ${props => props.theme.colors.grey};

    ${SearchIcon} {
        position: relative;
        bottom: -1px;
        font-size: 27px;
        vertical-align: top;
    }
`;

const Header = ({ pageContext }) => {
    // Get menus as StaticQuery
    const { menus, contacts } = useStaticQuery(
        graphql`
            query SiteMenus {
                menus: allPrismicMenu {
                    edges {
                        node {
                            ...HeaderMenuContent
                        }
                    }
                }
                contacts: allPrismicContact {
                    edges {
                        node {
                            ...HeaderContactContent
                        }
                    }
                }
            }
        `
    );

    // Filter menu for current language
    const langMenu = menus.edges.find(({ node }) => node.lang === pageContext.lang).node;
    const langContact = contacts.edges.find(({ node }) => node.lang === pageContext.lang).node;

    // Get datas
    const { pages, sub_rythmo_link } = langMenu.data;

    // State
    const [activeSub, setActiveSub] = useState(0);
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const isMobile = useMedia(mqTabletLandscape, [true], false);

    return (
        <StyledHeader onMouseLeave={() => setActiveSub(null)}>
            <StyledWrapper>
                {/* <Link to={`/${pageContext.lang}/`}>{isMobile ? <LogoMobile /> : <Logo />}</Link> */}
                <Link to={`/${pageContext.lang}/`} onClick={() => setActiveSub(null)}>
                    <Logo />
                </Link>
                {isMobile ? (
                    <>
                        <Burger
                            isOpen={isMobileMenuOpen}
                            onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
                        />
                        <MenuMobile
                            {...langMenu.data}
                            {...langContact.data}
                            isOpen={isMobileMenuOpen}
                            onClickSubLink={() => setMobileMenuOpen(false)}
                        />
                    </>
                ) : (
                    <>
                        <StyledNav>
                            {pages.map(({ page }) => {
                                const { id, uid, data, url } = page;
                                return (
                                    <LinkHover
                                        key={id}
                                        to={url}
                                        data-uid={uid}
                                        onMouseEnter={event =>
                                            setActiveSub(event.currentTarget.dataset.uid)
                                        }
                                    >
                                        {data.title[0].text}
                                    </LinkHover>
                                );
                            })}
                            <LinkHover onMouseEnter={() => setActiveSub('contact')}>
                                Contact
                            </LinkHover>
                        </StyledNav>
                        <LangSwitch {...pageContext} />
                        <StyledSearch onClick={() => setActiveSub('search')}>
                            <SearchIcon />
                        </StyledSearch>
                        <SubMenu isOpen={activeSub === 'search'}>
                            <Search
                                {...pageContext}
                                onValidation={() => setActiveSub(null)}
                                shouldFocus={activeSub === 'search'}
                            />
                        </SubMenu>
                        {sub_rythmo_link && (
                            <SubMenu isOpen={activeSub === sub_rythmo_link.uid}>
                                <SubMenuRythmo
                                    {...langMenu.data}
                                    handleClickLink={() => setActiveSub(null)}
                                />
                            </SubMenu>
                        )}
                        <SubMenu isOpen={activeSub === 'contact'}>
                            <SubMenuContact {...langContact.data} />
                        </SubMenu>
                    </>
                )}
            </StyledWrapper>
        </StyledHeader>
    );
};

export default Header;
