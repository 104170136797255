import React from 'react';
import styled from 'styled-components';

const StyledSeparator = styled.hr`
    position: relative;
    width: 100%;
    height: 1px;
    margin: 0;
    padding: 0;
    border: 0;
    background: ${props => props.theme.colors[props.color]};
`;

const BorderSeparator = props => <StyledSeparator {...props} />;

BorderSeparator.defaultProps = {
    color: 'border'
};

export default BorderSeparator;
