import './src/styles/global.css';
import './src/core/fragments';
import './src/core/polyfills';

import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './src/styles';
import Layout from './src/components/Layout';
import ModalProvider from './src/store/ModalProvider';
import MapsProvider from './src/store/MapsProvider';

export const wrapRootElement = ({ element }) => {
    return (
        <ThemeProvider theme={theme}>
            <ModalProvider>
                <MapsProvider>{element}</MapsProvider>
            </ModalProvider>
        </ThemeProvider>
    );
};

export const shouldUpdateScroll = () => {
    return false;
};

export const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>;
